import { FC } from "react";
import styles from "./App.module.css";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as Logo } from "./logo.svg";

const App: FC = (): JSX.Element => {
  return (
    <div>
      <Logo className={styles.logo} />
      <h2 className={styles.underoverskrift}>
        {"Fredag 27. januar 2023 - Tromsø"}
      </h2>
      <h3 className={styles.sted}>{"Helmersen Delikatesser og Vinbar"}</h3>
      {/* <a
        href={"https://jusfest.hoopla.no/sales/oslo2022"}
        className={styles.infotekst}
      >
        {"Kjøp billetter"} </a>*/}
      <a href={"https://instagram.com/jusfestoslo"}>
        <Instagram className={styles.instagram} />
      </a>
    </div>
  );
};

export default App;
